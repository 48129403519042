import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Гольф-клуб Holʹf
			</title>
			<meta name={"description"} content={"Відкрийте для себе неперевершену елегантність на зелені"} />
			<meta property={"og:title"} content={"Головна | Гольф-клуб Holʹf"} />
			<meta property={"og:description"} content={"Відкрийте для себе неперевершену елегантність на зелені"} />
			<meta property={"og:image"} content={"https://nexophase.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://nexophase.com/images/12303252.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://nexophase.com/images/12303252.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://nexophase.com/images/12303252.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://nexophase.com/images/12303252.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://nexophase.com/images/12303252.png"} />
			<meta name={"msapplication-TileImage"} content={"https://nexophase.com/images/12303252.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="80px 0 90px 0"
			md-padding="80px 0 50px 0"
			overflow-x="hidden"
			overflow-y="hidden"
			quarkly-title="Hero-14"
		>
			<Override slot="SectionContent" width="100%" min-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				md-flex-direction="column"
				md-justify-content="flex-end"
				md-align-items="flex-end"
				sm-z-index="2"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					width="60%"
					padding="80px 80px 80px 80px"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					sm-padding="50px 25px 50px 25px"
					sm-position="relative"
					sm-z-index="2"
				>
					<Text margin="0px 0px 20px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond" color="#1bc0b4">
					Гольф-клуб
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 600 72px/1.2 --fontFamily-serifGaramond" sm-font="normal 600 42px/1.2 --fontFamily-serifGaramond">
					Holʹf
					</Text>
					<Text margin="0px 0px 60px 0px" font="--base">
					Вирушайте у виняткову подорож у гольф у гольф-клубі Holʹf, де краса спорту поєднується з мистецтвом релаксації. Наші розкішні, ретельно доглянуті поля розташовані в спокійному оточенні і створені для того, щоб кинути виклик як новачкам, так і досвідченим гравцям у гольф. Від першого до останнього удару, ваш візит сюди обіцяє більше, ніж просто гру - це омолоджуюча втеча на природу.
					</Text>
					<Button
						background="#1bc0b4"
						border-radius="4px"
						padding="16px 28px 16px 28px"
						font="--base"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="solid"
						border-color="#1bc0b4"
						hover-color="--darkL1"
						hover-background="rgba(237, 97, 26, 0.03)"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
					>
						Контакти
					</Button>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					width="40%"
					justify-content="flex-end"
					align-items="center"
					md-margin="-120px 0px 0px 0px"
					md-width="60%"
					sm-position="relative"
					sm-z-index="1"
				>
					<Image
						src="https://nexophase.com/images/1.jpg"
						display="block"
						margin="0px -100px 0px 0px"
						height="100%"
						md-margin="0px 0 0px 0px"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="120px 0 140px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-4">
			<Text margin="0px 0px 0px 0px" font="normal 700 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
			Чому варто обрати Holʹf?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="50px 0px 0px 0px"
				md-flex-direction="column"
				sm-margin="30px 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					margin="0px 10% 0px 0px"
					padding="4px 0px 0px 0px"
					md-width="100%"
					md-margin="0px 10% 50px 0px"
					sm-padding="0 0px 0px 0px"
					sm-margin="0px 10% 35px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sansHelvetica" color="#35363a">
					У гольф-клубі Holʹf ми пишаємося тим, що створюємо захоплюючий досвід, який виходить за рамки традиційного. Наш клуб має найсучасніше обладнання, включаючи професійний магазин з найновішим спорядженням, різноманітні тренувальні майданчики для вдосконалення ваших навичок, а також затишний клубний будинок, де ви можете розслабитися і згадати свої ігри. Ми прагнемо зберегти цілісність гри, одночасно впроваджуючи інновації, щоб покращити ваш час на полі.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 50px 0px" font="normal 700 26px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
					Найкращі поля
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 700 26px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
					Винятковий сервіс
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 700 26px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
					Першокласні тренери
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 700 26px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
					Прихильність до сталого розвитку
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					width="80%"
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					sm-text-align="left"
					sm-font="--headline3"
				>
					Приєднуйтесь до нас на зеленому полі
				</Text>
			</Box>
			<Box
				display="flex"
				width="45%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						top={0}
						right={0}
						src="https://nexophase.com/images/2.jpg"
						display="block"
						width="100%"
						bottom={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						left={0}
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 0px 0px"
					color="--darkL2"
					md-text-align="left"
				>
					Насолоджуйтесь нашими першокласними зручностями, створеними для того, щоб доповнити ваш досвід гри в гольф. Наш клуб пропонує вишукані ресторани, оздоровчу зону з фітнесом і спа-послугами, а також індивідуальні тренування за попереднім записом. Ці зручності гарантують, що кожен гість знайде щось, що збагатить його перебування як на зеленому полі, так і за його межами.
				</Text>
			</Box>
			<Box
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				display="flex"
				width="55%"
				flex-direction="column"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 45% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						src="https://nexophase.com/images/3.jpg"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						width="100%"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--darkL2"
					md-text-align="left"
				>
					Наш персонал прагне надавати уважне та персоналізоване обслуговування, гарантуючи, що кожен аспект вашого візиту буде бездоганним.
				</Text>
				<Box
					position="relative"
					padding="0px 0px 45% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
				>
					<Image
						object-fit="cover"
						width="100%"
						bottom="0px"
						min-height="100%"
						src="https://nexophase.com/images/4.jpg"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-12">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="100%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					position="relative"
					padding="0px 0px 70% 0px"
				>
					<Image
						width="100%"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						src="https://nexophase.com/images/5.jpg"
						object-fit="cover"
						right={0}
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="100%"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Кожне поле створено з унікальними ландшафтами та стратегічними завданнями.
				</Text>
			</Box>
		</Section>
		<Components.Banner />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});